$(function () {

	// header menu
	$('#header-menu').on('show.bs.collapse', function () {
		$('body').addClass('overflow-hidden');
		$('html, body').animate({
			scrollTop: 0
		}, 500);
	});
	$('#header-menu').on('hide.bs.collapse', function (e) {
		if (e.target.id == 'header-menu') {
			$('body').removeClass('overflow-hidden');
		}
	});

	// services filter
	$('#services__tabs').on('click', 'li', function () {
		var filter = $(this).data('filter'),
			list = $('#services__list');

		$('#services__tabs li').removeClass('is-active');
		$(this).addClass('is-active');

		if (filter === '*') {
			list.find('> *').removeClass('d-none');
		} else {
			list.find('> *').addClass('d-none');
			list.find('.' + filter).removeClass('d-none');
		}
	});

	// add row form
	$('#form-append-add').on('click', function(){
		var form = $('#form-append');
		var formChilds = $('#form-append > div').length;
		var markup = '<div class="row align-items-xl-end"><div class="col-xl"><div class="form-item mb-0"><div><input type="date" name="График отгрузки - дата (' + formChilds + 1 + ')"></div></div></div><div class="col-xl"><div class="form-item mb-0"><div><input type="text" name="График отгрузки - вагонов (' + formChilds + 1 + ')" placeholder="вагонов"></div></div></div></div>';
		form.append(markup);

		if ($('#form-append > div').length > 0) {
			$("#form-append-remove").removeAttr('disabled');
		}
	});
	
	// Find and remove selected table rows
	$("#form-append-remove").on('click', function(){
		var formChilds = $('#form-append > div').length;
		var last = $('#form-append > div:last-child');

		if (formChilds > 0) {
			last.remove();
		}

		if ($('#form-append > div').length == 0) {
			$(this).attr('disabled', true);
		}
	});
});